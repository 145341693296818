import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Img from "gatsby-image"
import "../../styles/article.css"
import highlightCode from '../../utils/highlightCode'
import moment from 'moment'


const BlogPost = ({ data }) => {
  const post = data.strapiArticle
  useEffect(() => {
    highlightCode()
  })
  return (
    <Layout>
      <Seo title={`${post.Title} | ${data.site.siteMetadata?.title}`}
        description={post.Preview}
        path={`/article/${post.Slug}`}
        author={post.author?.Name}
        image={`${post.Cover?.formats?.medium?.url}`}
        ogType='article' />
      <Helmet>
        <script type='text/javascript' src='https://platform-api.sharethis.com/js/sharethis.js#property=61570418feb2560019eec8a0&product=sop' async='async'></script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${data.site.siteMetadata?.siteUrl}/article/${post.Slug}"
              },
              "headline": "${post.Preview}",
              "image": "${data.site.siteMetadata.siteUrl}${post.Cover?.localFile?.childImageSharp?.fixed.src}",  
              "author": {
                "@type": "Person",
                "name": "${post.author?.Name}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Coding Samples",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${data.site.siteMetadata?.siteUrl}${data.logo.publicURL}"
                }
              },
              "datePublished": "${moment(post.Creation).format('LL')}",
              "dateModified": "${moment(post.modified_at).format('LL')}"
            }
      `   }
        </script>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://codingsamples.dev/"
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "${post.category?.Name}}",
              "item": "https://codingsamples.dev/category/${post.category?.Slug}/"
            }, {
              "@type": "ListItem",
              "position": 3,
              "name": "${post.Title}",
              "item": "https://codingsamples.dev/article/${post.Slug}/"
            }]
          }
        `}
        </script>
      </Helmet>
      <div className="container-small mt-5">
        <ul className="breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><Link to={`/category/${post.category?.Slug}/`}>{post.category?.Name}</Link></li>
          <li>{post.Title}</li>
        </ul>
      </div>
      <article className="container-small mt-2">
        <div className="mx-3">
          <h1>{post.Title}</h1>
          <div className="d-flex mt-3 align-items-center">
            <div className="pe-1">
              <Img className="rounded-circle border border-white d-block" fixed={post.author?.Picture?.localFile?.childImageSharp?.fixed} />
            </div>
            <div className="px-1">
              <div className="author-name m-0">{post.author?.Name}</div>
            </div>
            <div className="ps-2 pe-1">
              <div className="publication m-0">{moment(post.Creation).format('MMM D')}</div>
            </div>
            <div className="px-1 dot">·</div>
            <div className="px-1">
              <div className="publication m-0">{post.LengthRead} min read</div>
            </div>
          </div>
          <section className="mt-3">
            <div dangerouslySetInnerHTML={{ __html: post.Body }}></div>
          </section>
        </div>


      </article>
    </Layout>
  )
}

export default BlogPost;
export const query = graphql`
  query($Slug: String!) {
    logo: file (base: { eq: "codingsamples-dark.png" }) {
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    strapiArticle(Slug: { eq: $Slug }) {
      Body
      Title
      Preview
      LengthRead
      Creation
      Slug
      updated_at
      category {
        Name
        Slug
      }
      Cover {
        localFile {
          childImageSharp {
            fixed(width: 900) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        formats {
          medium {
            url
          }
        }
      }
      author {
        Name
        Picture {
          localFile {
            childImageSharp {
              fixed(width: 32, height: 32) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`